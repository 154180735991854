import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class LoginService {
    private tokenUrl = `${environment.apiBaseUrl}/api/v1/login/access-token`;
    private registerUrl = `${environment.apiBaseUrl}/api/v1/users/`;

    constructor(private http: HttpClient) { }

    login(username: string, password: string): Observable<any> {
        const body = new URLSearchParams();
        body.set('grant_type', 'password');
        body.set('username', username);
        body.set('password', password);

        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

        return this.http.post(this.tokenUrl, body.toString(), { headers });
    }

    registerAsAdmin(fullName: string, email: string, password: string): Observable<any> {
        const adminLogin = new URLSearchParams();
        adminLogin.set('grant_type', 'password');
        adminLogin.set('username', 'GFuser01@ki-befundung.de');
        adminLogin.set('password', 'gfapp123');
        // adminLogin.set('client_secret', '...'); // Only if required!

        const loginHeaders = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

        return new Observable(observer => {
            this.http.post<any>(this.tokenUrl, adminLogin.toString(), { headers: loginHeaders }).subscribe({
                next: (tokenResponse) => {
                    const token = tokenResponse.access_token;

                    const userPayload = {
                        email,
                        is_active: true,
                        is_superuser: false,
                        full_name: fullName,
                        password
                    };

                    const headers = new HttpHeaders({
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    });

                    this.http.post(this.registerUrl, userPayload, { headers }).subscribe({
                        next: (res) => observer.next(res),
                        error: (err) => observer.error(err)
                    });
                },
                error: (err) => observer.error(err)
            });
        });
    }

    getCurrentUser(token: string): Observable<any> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${token}`
        });

        return this.http.get(`${environment.apiBaseUrl}/api/v1/users/me`, { headers });
    }

}
