import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, tap, throwError, BehaviorSubject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoginService } from '../../login/services/login.component.service'; // Adjust path if needed

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly TOKEN_KEY = 'access_token';
  private isAuthenticatedSubject = new BehaviorSubject<boolean>(this.hasToken());

  isAuthenticated$ = this.isAuthenticatedSubject.asObservable();

  constructor(
    private loginService: LoginService,
    private router: Router
  ) {}

  private hasToken(): boolean {
    return !!localStorage.getItem(this.TOKEN_KEY);
  }

  login(username: string, password: string): Observable<any> {
    return this.loginService.login(username, password).pipe(
      tap(response => {
        if (response && response.access_token) {
          localStorage.setItem(this.TOKEN_KEY, response.access_token);
          this.isAuthenticatedSubject.next(true);
          this.router.navigate(['/dashboard']); // Or wherever login should redirect
        } else {
          // Handle cases where login is successful but no token is returned (shouldn't happen with OAuth2 password flow)
          console.error('Login successful but no access token received.');
          localStorage.removeItem(this.TOKEN_KEY); // Ensure clean state
          this.isAuthenticatedSubject.next(false);
        }
      }),
      catchError(error => {
        // Handle login errors (e.g., invalid credentials)
        console.error('Login failed:', error);
        localStorage.removeItem(this.TOKEN_KEY); // Ensure clean state
        this.isAuthenticatedSubject.next(false);
        return throwError(() => new Error('Login failed')); // Propagate error
      })
    );
  }

  logout(): void {
    localStorage.removeItem(this.TOKEN_KEY);
    this.isAuthenticatedSubject.next(false);
    this.router.navigate(['/login']);
  }

  getToken(): string | null {
    return localStorage.getItem(this.TOKEN_KEY);
  }

  isAuthenticated(): boolean {
    // Check not just for token existence but potentially expiry if token includes it
    const token = this.getToken();
    // Add token validation/expiry check here if possible/needed
    const hasValidToken = !!token;
    if (this.isAuthenticatedSubject.value !== hasValidToken) {
        this.isAuthenticatedSubject.next(hasValidToken); // Update subject if state mismatch
    }
    return hasValidToken;
  }
}
