import { Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';

export const routes: Routes = [
  {
    path: 'login',
    loadComponent: () => import('./login/login.component').then((m) => m.LoginComponent), 
  },
  {
    path: 'report-create',
    loadComponent: () => import('./report-create/report-create.component').then((m) => m.ReportCreateComponent),
    canActivate: [AuthGuard], 
  },
  {
    path: 'report-details/:id',
    loadComponent: () => import('./report-edit/report-edit.component').then((m) => m.ReportEditComponent),
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard',
    loadComponent: () => import('./dashboard/dashboard.component').then((m) => m.DashboardComponent),
    canActivate: [AuthGuard],
  },
  {
    path: '',
    redirectTo: 'login', 
    pathMatch: 'full',
  },
];
