export const environment = {
  production: false,
   apiBaseUrl: 'https://api.ki-befundung.digital-at-work.de',
   firebaseConfig: {
    apiKey: "AIzaSyDpDzuqc7X3x5xzd7P166eX9YQv3nmjzHs",
    authDomain: "gfapp-69404.firebaseapp.com",
    projectId: "gfapp-69404",
    storageBucket: "gfapp-69404.appspot.com",
    messagingSenderId: "819805173550",
    appId: "1:819805173550:web:fe23751b22c8d2c6762cbf"
  }
};




